<template>
  <div >
    <v-row class="mt-5" id="step3-right-page" align="center" :justify="currentPage == 3 ? 'start' : 'center'">
      <v-col :cols="donorCatchUpSfId ? '12': '4'" v-if="currentPage === 3">
        <v-text-field id="amountInput" ref="amountInput" :class="donorCatchUpSfId ? 'w-75' : ''" placeholder="Amount" min="0" hide-details="auto" type="number" v-model.number="model.amount" required
            :error-messages="$helpers.errorMsg('name', valid.model.amount, 'Amount')" @input="amountUpdate"
            @keydown="$helpers.avoidInputArrowKeyPress" @focus="$helpers.avoidInputScrollable"></v-text-field>
      </v-col>
      <v-row class="ma-0 pa-0 py-2" v-if="donorCatchUpSfId && currentPage == 3">
        <v-col cols="3">
            <v-subheader class="subtitle-1 px-0">Receipt Name</v-subheader>
        </v-col>
        <v-col cols="9">
            <v-text-field class="pt-0" refs="receipt_name" placeholder="Receipt Name" hide-details="auto" v-model.trim="model.receipt_name" required
              :error-messages="$helpers.errorMsg('name', valid.model.receipt_name, 'Receipt Name')"/>
        </v-col>
      </v-row>
      <v-col cols="4" v-if="!donorCatchUpSfId && currentPage === 3">
          <v-btn block type="button" :outlined="model.paymentFrequency !== 'O'" :depressed="model.paymentFrequency === 'O'" class="mt-3 white--text"
            :color="model.paymentFrequency === 'O' ? 'primary' : 'indigo'" @click="freqSelect('O', producerActCampModel.sf_campaign_once)">
              Once
          </v-btn>
      </v-col>
      <v-col v-if="!donorCatchUpSfId && currentPage === 3" cols="4" class="d-flex align-center justify-start">
          <v-btn :block="model.paymentFrequency === 'O'" type="button" :outlined="model.paymentFrequency !== 'M'" :depressed="model.paymentFrequency === 'M'" class="w-75 mt-3 mr-2 white--text"
            :color="model.paymentFrequency === 'M' ? 'primary' : 'indigo'" @click="freqSelect('M', producerActCampModel.sf_campaign_monthly)">
              Monthly
          </v-btn>
          <vc-date-picker v-model="model.start_date" :min-date="today" v-if="model.paymentFrequency === 'M'"  is-required
            @dayclick="model.payDateChanged = true" :masks="{ L: 'YYYY-MM-DD' }" :model-config="{type: 'string', mask: 'YYYY-MM-DD' }">
            <template v-slot="{ togglePopover }">
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="align-center justify-center mt-3" fab dark :elevation="!model.payDateChanged ? '0' : '2'"
                      :small="!model.payDateChanged" :x-small="model.payDateChanged" :color="!model.payDateChanged ? 'transparent' : 'primary'"
                      @click="togglePopover">
                        <v-icon :color="!model.payDateChanged ? 'primary' : 'white'" dark center v-bind="attrs" v-on="on"
                          dense>mdi-clock-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ model.payDateChanged ? model.start_date : 'Set First Payment Date' }}</span>
                </v-tooltip>
            </template>
          </vc-date-picker>
      </v-col>
      <v-row v-if="!donorCatchUpSfId && currentPage == 3" align="center" class="ma-0 col col-4" >Payment method</v-row>
      <v-col v-if="!donorCatchUpSfId" :cols="currentPage == 3" >
          <v-btn block type="button" :outlined="model.paymentMethod !== 'CC'" :depressed="model.paymentMethod === 'CC'" class="white--text"
            :color="model.paymentMethod === 'CC' ? 'primary' : 'indigo'" @click="payMethodChange('CC');">
              Credit card
          </v-btn>
      </v-col>
      <v-col v-if="!donorCatchUpSfId" :cols="currentPage == 3">
          <v-btn block type="button" :outlined="model.paymentMethod !== 'DD'" :depressed="model.paymentMethod === 'DD'" class="white--text"
            :color="model.paymentMethod === 'DD' ? 'primary' : 'indigo'" @click="payMethodChange('DD');">
              Direct Debit
          </v-btn>
      </v-col>
      <template v-if="!donorCatchUpSfId && $helpers.getCurData('curUserPermissions').indexOf('manual_payment_methods') !== -1 && currentPage == 3">
        <v-col cols="4">
            <v-btn block type="button" :outlined="model.paymentMethod !== 'C'" :depressed="model.paymentMethod === 'C'" class="white--text"
              :color="model.paymentMethod === 'C' ? 'primary' : 'indigo'" @click="payMethodChange('C');">
                Cash
            </v-btn>
        </v-col>
        <v-col cols="4">
            <v-btn block type="button" :outlined="model.paymentMethod !== 'CQ'" :depressed="model.paymentMethod === 'CQ'" class="white--text"
              :color="model.paymentMethod === 'CQ' ? 'primary' : 'indigo'" @click="payMethodChange('CQ');">
                Cheque
            </v-btn>
        </v-col>
        <v-col cols="4">
            <v-btn block type="button" :outlined="model.paymentMethod !== 'DT'" :depressed="model.paymentMethod === 'DT'" class="white--text"
              :color="model.paymentMethod === 'DT' ? 'primary' : 'indigo'" @click="payMethodChange('DT');">
                Direct transfer
            </v-btn>
        </v-col>
      </template>
      <!-- <v-col cols="10" v-if="currentPage === 3">
        <v-row align="center" justify="center">
            <v-col cols="5" class="text-end py-0">Amount :</v-col>
            <v-col cols="5" class="py-0">$ {{ model.amount }}  /  {{ model.paymentFrequency === 'O' ? 'Once' : 'Monthly' }}</v-col>
        </v-row>
      </v-col> -->
      <v-row class="ma-0" align="center" justify="center">
        <v-col cols="7" v-if="['CQ', 'C', 'DT'].indexOf(model.paymentMethod) === -1 && currentPage == 3">
          <v-select @change="cardChange();" dense hide-details="auto" class="card-existing-badge"
              :outlined="!model.newCard && currentPage == 3"
              :filled="(model.newCard && currentPage == 3) || model.newCard"
              :disabled="model.newCard && currentPage == 3"
              v-model="model.existingCards"
              :items="donorExistingCardList"
              :class="!model.newCard && model.existingCards ? 'selectable' : ''"
              :color="!model.newCard && model.existingCards ? 'white' : 'primary'"
              :background-color="!model.newCard && model.existingCards ? 'primary' : ''"
              item-text="value"
              item-value="existing_pay_id"
              :placeholder="'Existing Card/s'"
              return-object
          >
            <template v-slot:selection="{ item }">
                <span :class="!model.newCard && model.existingCards ? 'text-white' : 'primary--text'" >{{ item.value }}</span>
            </template>
            <template v-slot:append>
              <v-badge bordered color="secondary" :content="existingCardLength"></v-badge>
            </template>
          </v-select>
        </v-col>
        <v-col cols="3" v-if="['CQ', 'C', 'DT'].indexOf(model.paymentMethod) === -1 && currentPage == 3">
            <v-switch dense hide-details class="pt-0"
              v-model="model.newCard"
              label="Use New card"
              color="primary"
            ></v-switch>
        </v-col>
      </v-row>
      <payment-options :v="valid" v-if="['CQ', 'C', 'DT'].indexOf(model.paymentMethod) !== -1 && currentPage == 3" />
      <v-col cols="12" v-if="currentPage == 3 && ['CQ', 'C', 'DT'].indexOf(model.paymentMethod) === -1" class="pt-0">
        <stripe  @updatePayDetails="model={...model, ...$event};" :stripe="stripeEl" :type="model.paymentMethod" :existingCards="model.existingCards"/>
      </v-col>
      <v-col v-if="!donorCatchUpSfId && !latestIncentiveLoading && currentIncentive && currentIncentive.optin == 1"
        :cols="['C', 'DT'].indexOf(model.paymentMethod) !== -1 ? '8' : model.paymentMethod == 'CQ' ? '12' : '10'"
        :class="{'pt-0' : ['C', 'DT',].indexOf(model.paymentMethod) === -1 || ['CC', 'DD',].indexOf(model.paymentMethod) !== -1 }">
        <v-switch dense
            v-model="model.opt_in" :false-value="null" :true-value="currentIncentive.optin_val"
            :label="currentIncentive ? currentIncentive.optin_desc || '' : ''"
            color="primary"
            hide-details
          ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "stepThree",
    props: ["v", "stripeEl", "donorCatchUpSfId"],
    components: {
        Stripe: () => import("@/components/common/stripe"),
        Modal: () => import("@/components/common/modal"),
        PaymentOptions: () => import("@/pages/dashboard/components/PaymentOptions"),
    },
    data: (vm) => ({
        valid: {},
        menuTxtbox: false,
        stripe: null,
        today: vm.$helpers.getMomentDatas('YYYY-MM-DD'),
        currentPage: vm.currentStep
    }),
    computed: {
        ...mapGetters(["latestIncentiveLoading", "currentIncentive", "producerActCampModel", "currentStep"]),

        donorExistingCardList() {
            const existCardLists = this.$store.getters["donorExistingCardList"];
            if (this.model.paymentMethod === 'DD') {
                return existCardLists.filter(item => item.type === 'au_becs_debit') || [];
            }
            return existCardLists.filter(item => item.type === 'card') || [];
        },
        existingCardLength() {
            return `${this.donorExistingCardList ? this.donorExistingCardList.length : 0}`;
        },
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("setModel", newValue);
            },
        }
    },
    created() {
        this.model = {...{
            existingCards: null,
            start_date: this.$helpers.getMomentDatas('YYYY-MM-DD'),
            cardValidate: false,
            newCard: true,
            payDateChanged: false,
            paymentFrequency: 'O',
            paymentMethod: 'CC'
        }, ...this.model};
        this.valid = this.v;
        this.model.stripeErr = '';
    },
    watch: {
        'model.newCard': function (newVal, oldVal) {
            if (newVal) {
                this.$store.commit("updateModel", {cardValidate: false});
                this.model.existingCards = null;
            }
        },
        'model.paymentMethod': function (newVal, oldVal) {
            if (['CQ', 'C', 'DT'].indexOf(newVal) !== -1) {
                this.$store.commit("updateModel", {cardValidate: true});
            }
        },
        'model.receipt_name': function(newVal) {
            if (newVal) this.model.receipt_name = this.$filters.titleCase(newVal);
        },
        'currentStep': {
            handler: function(value) {
                this.currentPage = value;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        cardChange() {
            this.model.cardValidate = true;
            this.model = {...this.model, ...this.model.existingCards}
        },
        payMethodChange(type) {
            // this.model.start_date = this.today;
            this.model.paymentMethod = type;
            this.model.existingCards = null;
            this.$store.commit("updateModel", {cardValidate: false});
        },
        amountUpdate() {
            this.$store.dispatch('modelUpdate', this.model);
        },
        freqSelect(freq, campID) {
            if (!this.model.adv_campaign_id) {
                this.model.sf_campaign_id = campID;
            } else {
                this.model.sf_campaign_id = this.model.adv_campaign_id;
            }
            this.model.paymentFrequency = freq;
        }
    }
};
</script>